export default class CAS {
    constructor() {
        let params = new URLSearchParams(window.location.search)
        let ticket = params.get('ticket')

        this.ticket = ticket
        this.id = null
    }

    login() {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);

        // Remove the "ticket" parameter
        params.delete("ticket");

        // Construct the service URL with remaining params
        let service = encodeURIComponent(window.location.origin + window.location.pathname + (params.toString() ? "?" + params.toString() : ""));

        // Redirect to the login URL
        window.location = `https://secure.its.yale.edu/cas/login?service=${service}`;
    }

    logout() {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);

        // Remove the "ticket" parameter
        params.delete("ticket");

        // Construct the service URL with remaining params
        let service = encodeURIComponent(window.location.origin + window.location.pathname + (params.toString() ? "?" + params.toString() : ""));

        // Redirect to the login URL
        window.location = `https://secure.its.yale.edu/cas/logout?service=${service}`;
    }

    hasTicket() {
        return !!this.ticket
    }

    // this removes the CAS ticket URL param without causing the browser back button to get stuck in a redirect loop
    cleanURL() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        // Remove the "ticket" parameter
        params.delete('ticket');

        // Construct the new URL
        const newUrl = url.pathname + (params.toString() ? '?' + params.toString() : '');

        // Replace the current state in history
        window.history.replaceState({}, document.title, newUrl);
    }

    async validate(ticket) {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);

        // Remove the "ticket" parameter
        params.delete("ticket");

        // Construct the service URL with remaining params
        let service = encodeURIComponent(window.location.origin + window.location.pathname + (params.toString() ? "?" + params.toString() : ""));
        let response = await fetch('/validate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ticket: ticket,
                service: service
            })
        })

        return response
    }

    async parse(response) {
        return await response.text().then(str => {
            let line = str.split('\n')

            return {
                success: line[0] === 'yes',
                id: line[1]
            }
        })
    }

    async isAuthenticated() {
        if (!this.ticket) {
            return { success: false }
        }

        return await this.validate(this.ticket)
            .then(response => this.parse(response))
            .then(obj => {
                if (obj.success) {
                    this.id = obj.id
                }
                return obj
            })
    }

    async person() {
        if (!this.id) {
            return null
        }

        return await fetch('/person', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: this.id
            })
        })
            .then(response => response.json())
            .then(json => {
                if (!json[0].data) {
                    return null
                }
                return json[0].data.items[0]
            })
    }
}